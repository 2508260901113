import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled/macro";

export const ImageWithFetchPrio = styled("img", {
  shouldForwardProp: isPropValid,
})<{
  fetchPriority: "auto" | "high" | "low";
}>``;

export const LegacyBannerImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
